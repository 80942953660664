import { makeStyles } from "@material-ui/core";
import { BACKGROUND } from "../../constants/common-constants";
import { BackgroundTheme } from "../../types/common";

export const useVeracityModalStyles = makeStyles((theme) => ({
    root: {
        position: "relative",
        "& .MuiIconButton-root": {
            "& svg": {
                color: theme.palette.text.secondary,
            }
        }
    },
    fullVeracityIcon: {
        "& svg": {
            width: "1.406rem",
            height: "1.406rem"
        }
    },
    container: {
        display: "flex",
        gap: "12px",
        border: "none",
        marginBottom: "12px",
        zIndex: 1
    },
    dropdown: {
        padding: 0,
        position: "absolute",
        width: 290,
        // height: 208,
        top: 10,
        right: 10,
        zIndex: 2,
        borderRadius: 4,
        backgroundColor: theme.palette.background.paper,
        boxShadow: "rgb(101 119 134 / 20%) 0px 0px 15px, rgb(101 119 134 / 15%) 0px 0px 3px 1px",
        "& .MuiListItem-root": {
            height: 52,
            "& svg": {
                marginRight: 15
            },
            "&:hover": {
                cursor: "pointer",
                backgroundColor: theme.palette.secondary.main
            }
        }
    },
    content: {
        top: "-20%"
    },
    dialogContent: {
        width: 598,
        minHeight: 100,
        padding: "10px 20px 10px 20px"
    },
    postCounter: {
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
        color: "#FFF",
        fontWeight: 600,
        width: "50px",
        height: "50px",
        border: "none",
        borderRadius: "8px",
        position:"relative",
    },
    headerPostCounter: {
        position: "relative",
        "&::before": {
            content: '""',
            display: "inline-block",
            width: 2,
            height: 20,
            backgroundColor: "grey",
            borderRadius: "50%",
            position: "absolute",
            left: "50%",
            top: "100%",
            bottom: 0,
            transform: "translateX(-50%)",
        },
    },
    postReport: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        marginTop: "12px"
    },
    postReportList: {
        "& .MuiListItem-root": {
            paddingInline: 0,
            display: "flex",
            alignItems: "start",
            "& .MuiListItemIcon-root": {
                minWidth: "unset"
            },
            "& .MuiListItemText-root": {
                marginLeft: "6px"
            }
        }
    },
    typography: {
        // minWidth: "300px",
        // maxWidth: "325px",
        padding: theme.spacing(1),
        display: "flex",
        alignItems: "start"
    },
    referenceLink: {
        wordBreak: "break-word",
        cursor: "pointer",
        color:"#3396ff",
    },
    redirectReferenceLink:{
        wordBreak: "break-word",
        display: "flex",
        alignItems: "start",
        cursor: "pointer",
        color:"#3396ff",
        textDecoration:"none"
    },
    userContainer: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        gap: "12px",
        border: "none",
        margin: "12px 0px"
    },
    userCountReport: {
        display: "flex",
        alignItems: "center",
        gap: "12px",
        border: "none",
        marginBottom: "12px"
    },
    userScoreCount: {
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
        color: "#FFF",
        fontWeight: 600,
        width: "50px",
        minWidth: "50px",
        maxWidth: "50px",
        height: "50px",
        border: "none",
        borderRadius: "50%",
        zIndex: 1
    },
    userProfileReport: {
        display: "flex",
        gap: "12px",
        border: "none",
        "& .MuiTypography-h6": {
            fontWeight: "500",
            color: "#333333"
        }
    },
    userAvatar: {
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
        color: "#FFF",
        fontWeight: 600,
        width: "50px",
        minWidth: "50px",
        maxWidth: "50px",
        height: "50px",
        border: "none",
        borderRadius: "50%",
        zIndex: 1
    },
    stepperline: {
        width: "2px",
        backgroundColor: "grey",
        position: "absolute",
        top: "25px",
        bottom: "25px",
        left: "25px",
        zIndex: 0
    },
    divider: {
        backgroundColor: "#D9D9D9"
    },
    tweetScore: {
        fontSize: "16px",
        color: localStorage.getItem(BACKGROUND) !== BackgroundTheme.DEFAULT ? "inherit" : "#536471",
        // color: "#",
        fontWeight: 400,
        cursor: "pointer",
        backgroundColor: "rgba(217, 217, 217, 0.4)",
        padding: "3px 6px",
        borderRadius: "10px",
        "& span": {
            height: "12px",
            width: "12px",
            display: "inline-block",
            borderRadius: "50%",
            marginRight: "2px"
        }
    },
    referenceLinkWrapper: {},
    referenceLinkHeader: {
        display: "flex",
        alignItems: "center",
        marginTop: 10
    },
    thumbWrapper: {
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
        color: "#FFF",
        fontWeight: 600,
        backgroundColor: "rgb(36, 187, 16)",
        width: "50px",
        height: "50px",
        border: "none",
        borderRadius: "8px"
    },
    heading: {
        marginLeft: "8px",
        fontSize: 20,
        lineHeight: "24px",
        color: localStorage.getItem(BACKGROUND) !== BackgroundTheme.DEFAULT ? "rgb(217, 217, 217)" : "#000000",
        fontWeight: 700
    },
    subHeading: {
        marginLeft: "60px",
        fontSize: 16,
        lineHeight: "20px",
        color: localStorage.getItem(BACKGROUND) !== BackgroundTheme.DEFAULT ? "rgb(217, 217, 217)" : "#000000",
        fontWeight: 500
    },
    referenceLinkInner: {
        // marginLeft: "50px"
    },
    referenceStepperline: {
        width: "2px",
        backgroundColor: "grey",
        position: "absolute",
        top: "25px",
        bottom: "100px",
        left: "25px",
        zIndex: 0
    },
    claimWrapper: {
        marginLeft: "10px",
        marginTop: "10px"
    },
    claimHeading: {
        fontSize: "18px",
        lineHeight: "22px",
        color: "inherit",
        fontWeight: 700
    },
    postScoreHeaderWrapper: {
        position: "relative",
        zIndex: 2
    },
    postScoreHeaderStepperline: {
        width: "2px",
        backgroundColor: "grey",
        position: "absolute",
        top: "25px",
        bottom: "55px",
        left: "25px",
        zIndex: -1
    },
    reportReferenceDetailModal: {
        "& .MuiDialog-container": {
            "& .MuiPaper-root": {
                maxHeight: "600px"
            }
        }
    },
    errorMsg: {
        textAlign: "center",
        fontSize: "20px",
        fontWeight: "bold"
    },
    referenceLinkList:{
        paddingLeft:75,
    },
    listItem :{
        listStyleType:"disc"
    },
    reportDetailsBtn:{
        backgroundColor:"unset",
        paddingInline: "15px",
        border: "1px solid",
        borderColor:"inherit",
        color:"inherit",
        fontWeight:"normal",
        "&:hover": {
            backgroundColor:"unset",
        }
    }
}));