import React, { FC, ReactElement } from "react";
import { useDispatch } from "react-redux";
import Button from "@material-ui/core/Button/Button";

import { followUser, processFollowRequest, setUserFollowing } from "../../../store/ducks/user/actionCreators";
import { useFollowButtonStyles } from "./FollowButtonStyles";
import { UserResponse } from "../../../types/user";
import { setFollowToUsersState } from "../../../store/ducks/users/actionCreators";
import { setFollowToUsersSearchState } from "../../../store/ducks/usersSearch/actionCreators";
import { useUserFollow } from "../../../context/UserFollowContext";
import { useLocation } from "react-router-dom";

interface FollowButtonProps {
    user?: UserResponse;
}

const FollowButton: FC<FollowButtonProps> = ({ user }): ReactElement => {
    const classes = useFollowButtonStyles();
    const dispatch = useDispatch();
    const location = useLocation();
    const { handleFollowRecord } = useUserFollow();

    const handleFollow = (event: React.MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();

        if (user?.isPrivateProfile) {
            dispatch(processFollowRequest(user.id));
        } else {
            dispatch(followUser({ userId: user!.id! }));
            handleFollowRecord({
                userId: user!.id,
                isFollow: true,
                path: location.pathname
            });
        }
    };

    return (
        <Button
            className={classes.outlinedButton}
            onClick={handleFollow}
            color="primary"
            variant="outlined"
            size="small"
        >
            Follow
        </Button>
    );
};

export default FollowButton;
