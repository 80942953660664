import { Theme, makeStyles } from "@material-ui/core";

interface IProps {
    contentBlurred?: boolean
}

export const useTweetImageStyles = makeStyles<Theme, IProps>((theme) => ({
    image: {
        position: "relative",
        marginTop: 10,
        "& img": {
            objectFit: "cover",
            width: "100%",
            height: "100%",
            // height: 'auto',
            // width: 230,
            // height: 230,
            // borderRadius: 20,
            borderColor: theme.palette.info.light
        },
        "& .small": {
            width: 260
        },
        "& video": {
            objectFit: "cover",
            width: "100%",
            borderColor: theme.palette.info.light,
            height:"100%",
            pointerEvents: props => props.contentBlurred ? "none" : "auto",
            "&::-webkit-media-controls" : {
                display: props => props.contentBlurred ? "none" : "auto",
            }
        },
    },
    imageLink: {
        display: 'block'
    },
    "grid-layout-single": {
        display: "grid",
        gap: "3px",
        "& .image1": {
            gridColumn: "1 / span 2",
            gridRow: "1 / span 2",
        },
        "& img": {
            borderRadius: "20px",
        },
        "& video": {
            borderRadius: "20px",
            pointerEvents: props => props.contentBlurred ? "none" : "auto",
            "&::-webkit-media-controls" : {
                display: props => props.contentBlurred ? "none" : "auto",
            }
        }
    },
    "grid-layout-double": {
        display: "grid",
        gap: "3px",
        gridTemplateColumns: "1fr 1fr", // Equal column widths
        gridTemplateRows: "1fr 1fr", 
        "& .image1": {
            gridColumn: "1",
            gridRow: "1 / span 2",
            height: 400,
            "& img": {
                borderTopLeftRadius: "20px",
                borderBottomLeftRadius: "20px",
                
            },
            "& video": {
                borderTopLeftRadius: "20px",
                borderBottomLeftRadius: "20px",
                pointerEvents: props => props.contentBlurred ? "none" : "auto",
                "&::-webkit-media-controls" : {
                    display: props => props.contentBlurred ? "none" : "auto",
                }
            }
        },
        "& .image2": {
            gridColumn: "2",
            gridRow: "1 / span 2",
            height: 400,
            "& img": {
                borderTopRightRadius: "20px",
                borderBottomRightRadius: "20px",
            },
            "& video": {
                borderTopRightRadius: "20px",
                borderBottomRightRadius: "20px",
                pointerEvents: props => props.contentBlurred ? "none" : "auto",
                "&::-webkit-media-controls" : {
                    display: props => props.contentBlurred ? "none" : "auto",
                }
            }
        }
    },
    "grid-layout-triple": {
        display: "grid",
        gap: "3px",
        gridTemplateColumns: "1fr 1fr", // Equal column widths
        gridTemplateRows: "1fr 1fr", 
        "& .image1": {
            gridColumn: "1",
            gridRow: "1",
            height: 198.5,
            "& img": {
                borderTopLeftRadius: "20px",
            },
            "& video": {
                borderTopLeftRadius: "20px",
                pointerEvents: props => props.contentBlurred ? "none" : "auto",
                "&::-webkit-media-controls" : {
                    display: props => props.contentBlurred ? "none" : "auto",
                }
            }
        },
        "& .image2": {
            gridColumn: "1",
            gridRow: "2",
            height: 198.5,
            "& img": {
                borderBottomLeftRadius: "20px",
               
            },
            "& video": {
                borderBottomLeftRadius: "20px",
                pointerEvents: props => props.contentBlurred ? "none" : "auto",
                "&::-webkit-media-controls" : {
                    display: props => props.contentBlurred ? "none" : "auto",
                }
            }
        },
        "& .image3": {
            gridColumn: "2",
            gridRow: "1 / span 2",
            height: 400,
            "& img": {
                borderTopRightRadius: "20px",
                borderBottomRightRadius: "20px",
            },
            "& video": {
                borderTopRightRadius: "20px",
                borderBottomRightRadius: "20px",
                pointerEvents: props => props.contentBlurred ? "none" : "auto",
                "&::-webkit-media-controls" : {
                    display: props => props.contentBlurred ? "none" : "auto",
                }
            }
        }
    },
    "grid-layout-quad": {
        display: "grid",
        gap: "3px",
        gridTemplateColumns: "1fr 1fr", // Equal column widths
        gridTemplateRows: "1fr 1fr", 
        "& .image1": {
            gridColumn: "1",
            gridRow: "1",
            height: 220,
            "& img": {
                borderTopLeftRadius: "20px",
            },
            "& video": {
                borderTopLeftRadius: "20px",
                pointerEvents: props => props.contentBlurred ? "none" : "auto",
                "&::-webkit-media-controls" : {
                    display: props => props.contentBlurred ? "none" : "auto",
                }
            }
        },
        "& .image2": {
            gridColumn: "1",
            gridRow: "2",
            height: 220,
            "& img": {
                borderBottomLeftRadius: "20px",
            },
            "& video": {
                borderBottomLeftRadius: "20px",
                pointerEvents: props => props.contentBlurred ? "none" : "auto",
                "&::-webkit-media-controls" : {
                    display: props => props.contentBlurred ? "none" : "auto",
                }
            }
        },
        "& .image3": {
            gridColumn: "2",
            gridRow: "1",
            height: 220,
            "& img": {
                borderTopRightRadius: "20px",
            },
            "& video": {
                borderTopRightRadius: "20px",
                pointerEvents: props => props.contentBlurred ? "none" : "auto",
                "&::-webkit-media-controls" : {
                    display: props => props.contentBlurred ? "none" : "auto",
                }
            }
        },
        "& .image4": {
            gridColumn: "2",
            gridRow: "2",
            height: 220,
            "& img": {
                borderBottomRightRadius: "20px",
            },
            "& video": {
                borderBottomRightRadius: "20px",
                pointerEvents: props => props.contentBlurred ? "none" : "auto",
                "&::-webkit-media-controls" : {
                    display: props => props.contentBlurred ? "none" : "auto",
                }
            }
        }
    },
    "grid-layout-five": {
        display: "grid",
        gap: "3px",
        marginTop: 10,
        gridTemplateColumns: "1fr 1fr", // Equal column widths
        gridTemplateRows: "1fr 1fr",
        "& .image1": {
            gridColumn: "1",
            gridRow: "1",
            height: 220,
            "& img": {
                borderTopLeftRadius: "20px"
            },
            "& video": {
                borderTopLeftRadius: "20px",
                pointerEvents: props => props.contentBlurred ? "none" : "auto",
                "&::-webkit-media-controls" : {
                    display: props => props.contentBlurred ? "none" : "auto",
                }
            }
        },
        "& .image2": {
            gridColumn: "1",
            gridRow: "2",
            height: 220,
            "& img": {
                borderBottomLeftRadius: "20px"
            },
            "& video": {
                borderBottomLeftRadius: "20px",
                pointerEvents: props => props.contentBlurred ? "none" : "auto",
                "&::-webkit-media-controls" : {
                    display: props => props.contentBlurred ? "none" : "auto",
                }
            }
        },
        "& .image3": {
            gridColumn: "2",
            gridRow: "1",
            height: 220,
            "& img": {
                borderTopRightRadius: "20px"
            },
            "& video": {
                borderTopRightRadius: "20px",
                pointerEvents: props => props.contentBlurred ? "none" : "auto",
                "&::-webkit-media-controls" : {
                    display: props => props.contentBlurred ? "none" : "auto",
                }
            }
        },
        "& .image4": {
            gridColumn: "2",
            gridRow: "2",
            height: 220,
            position:"relative",
            "& img": {
                borderBottomRightRadius: "20px"
            },
            "& video": {
                borderBottomRightRadius: "20px",
                pointerEvents: props => props.contentBlurred ? "none" : "auto",
                "&::-webkit-media-controls" : {
                    display: props => props.contentBlurred ? "none" : "auto",
                }
            },
            "& .imageCountWrapper":{
                position:"absolute",
                top:0,
                left:0,
                right:0,
                bottom:0,
                height:"100%",
                width:"100%",
                display:"flex",
                alignItems:"center",
                justifyContent:"center",
                backgroundColor:"#000000b5",
                borderBottomRightRadius: "20px",
            },
           "& .imageCount":{
                fontSize:50,
                color:"#FFFFFF",
            }
        },
    },
    skeletoLoader: {
        width: "100%",
        height: "100%",
        background: "linear-gradient(90deg, #e0e0e0 25%, #f8f8f8 50%, #e0e0e0 75%)",
        backgroundSize: "200% 100%",
        animation: "shimmer 1.5s infinite",
        position: "absolute",
        top: 0,
        left: 0,
    },
    "@keyframes shimmer": {
        "0%": {
            backgroundPosition: "200% 0",
        },
        "100%": {
            backgroundPosition: "-200% 0"
        }
    }
}));
