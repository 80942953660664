import { makeStyles } from "@material-ui/core";
import { BACKGROUND } from "../../constants/common-constants";
import { BackgroundTheme } from "../../types/common";

export const useHomeStyles = makeStyles((theme) => ({
    container: {
        position: "relative !important" as any
    },
    header: {
        position: "sticky!important" as any,
        top: 0,
        width: "unset!important" as any,
        justifyContent: "space-between",
        "& .MuiTypography-h5": {
            marginLeft: 16,
            display: "inline-block",
            verticalAlign: "middle"
        }
    },
    headerIcon: {
        paddingRight: 10
    },
    addForm: {
        padding: "15px"
    },
    divider: {
        height: 12,
        backgroundColor: theme.palette.divider
    },
    showPost: {
        textAlign: "center",
        padding: 16,
        cursor: "pointer",
        position: "sticky",
        top: "50px",
        zIndex: 1,
        backgroundColor: localStorage.getItem(BACKGROUND) === BackgroundTheme.DEFAULT ? "#fff" : "#000",
        "& .MuiTypography-body1": {
            color: "rgb(29, 155, 240)"
        },
        "& span": {
            fontSize: "18px"
        }
    }
}));
