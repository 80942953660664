import React, { FC, ReactElement, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Paper from "@material-ui/core/Paper";

import TweetComponent from "../../../../components/TweetComponent/TweetComponent";
import UsersItem, { UserItemSize } from "../../../../components/UsersItem/UsersItem";
import { useGlobalStyles } from "../../../../util/globalClasses";
import { fetchNotificationInfo, resetNotificationState } from "../../../../store/ducks/notifications/actionCreators";
import {
    selectIsNotificationInfoLoading,
    selectLoadingState,
    selectNotificationInfoTweet,
    selectNotificationInfoUser
} from "../../../../store/ducks/notifications/selectors";
import Spinner from "../../../../components/Spinner/Spinner";
import NotificationInfoHeader from "./NotificationInfoHeader/NotificationInfoHeader";
import { LoadingStatus } from "../../../../types/common";
import { NOTIFICATIONS } from "../../../../constants/path-constants";
import { Typography } from "@material-ui/core";

const NotificationInfo: FC = (): ReactElement => {
    const globalClasses = useGlobalStyles({});
    const dispatch = useDispatch();
    const history = useHistory();
    const params = useParams<{ id: string }>();
    const notificationUser = useSelector(selectNotificationInfoUser);
    const notificationTweet = useSelector(selectNotificationInfoTweet);
    const isLoading = useSelector(selectIsNotificationInfoLoading);
    const notificationIsLoading = useSelector(selectLoadingState);

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(fetchNotificationInfo(params.id));

        return () => {
            dispatch(resetNotificationState());
        };
    }, [params.id]);

    useEffect(() => {
        if (notificationIsLoading === LoadingStatus?.ERROR) {
            history.push(NOTIFICATIONS);
        }
    }, [notificationIsLoading]);

    return (
        <Paper className={globalClasses.pageContainer} variant="outlined">
            <NotificationInfoHeader />
            {isLoading ? (
                <Spinner paddingTop={150} />
            ) : (
                <>
                    {notificationTweet?.isDeleted ? (
                        <div
                            className={globalClasses.contentWrapper}
                            style={{
                                paddingTop: "30%"
                            }}
                        >
                            <Typography variant="h5" align="center">
                                This tweet has been deleted
                            </Typography>
                        </div>
                    ) : (
                        <>
                            <div className={globalClasses.contentWrapper}>
                                <TweetComponent tweet={notificationTweet} />
                            </div>
                            <UsersItem user={notificationUser} size={UserItemSize.MEDIUM} />
                        </>
                    )}
                </>
            )}
        </Paper>
    );
};

export default NotificationInfo;
