import React, { memo, ReactElement } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@material-ui/core";

import { followUser, processFollowRequest } from "../../../store/ducks/user/actionCreators";
import { selectUserProfileId, selectUserProfileIsPrivateProfile } from "../../../store/ducks/userProfile/selectors";
import { useUserPageStyles } from "../UserPageStyles";
import { setFollowToUserProfile } from "../../../store/ducks/userProfile/actionCreators";
import { useUserFollow } from "../../../context/UserFollowContext";
import { useLocation } from "react-router-dom";

const FollowUserButton = memo((): ReactElement => {
    const classes = useUserPageStyles();
    const dispatch = useDispatch();
    const location = useLocation();
    const { handleFollowRecord } = useUserFollow();
    const userProfileId = useSelector(selectUserProfileId);
    const isPrivateProfile = useSelector(selectUserProfileIsPrivateProfile);

    const handleFollow = (): void => {
        if (isPrivateProfile) {
            dispatch(processFollowRequest(userProfileId!));
        } else {
            dispatch(followUser({ userId: userProfileId! }));
            handleFollowRecord({
                userId: userProfileId!,
                isFollow: true,
                path: location.pathname
            });
            // dispatch(setFollowToUserProfile({ userId: userProfileId!, isFollower: true }))
        }
    };

    return (
        <Button
            className={classes.outlinedButton}
            onClick={handleFollow}
            color="primary"
            variant="outlined"
            size="large"
        >
            Follow
        </Button>
    );
});

export default FollowUserButton;
