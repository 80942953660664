import React, { FC, memo, ReactElement, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import { MediaIcon } from "../../icons";
import { setImages } from "../../store/ducks/addTweetForm/actionCreators";
import ActionIconButton from "../ActionIconButton/ActionIconButton";
import { setOpenSnackBar } from "../../store/ducks/actionSnackbar/actionCreators";
import { MAX_FILE_COUNT } from "../../constants/common-constants";

import { FFmpeg } from "@ffmpeg/ffmpeg";
import { fetchFile, toBlobURL } from "@ffmpeg/util";
import VideoTrimingModal from "../AddTweetForm/VideoTrimingModal/VideoTrimingModal";
import { ActionSnackbarStateType } from "../../store/ducks/actionSnackbar/contracts/state";
//import { useTweetForm } from "../../context/TweetFormContext";

interface UploadImagesProps {
    imageCount?: number;
    handleSetImage: (i: any) => any;
}

const ffmpeg = new FFmpeg();

const UploadImages: FC<UploadImagesProps> = ({ imageCount = 0, handleSetImage }): ReactElement => {
    const dispatch = useDispatch();
    // const { handleSetImage } = useTweetForm();
    const inputRef = useRef<HTMLInputElement>(null);
    const [isVideoTrimingVisible, setIsVideoTrimingVisible] = useState(false);
    const [videoFiles, setVideoFiles] = useState<File[]>([]);
    // const [validFiles, setValidFiles] = useState<any>([]);

    const handleClickImage = () => {
        inputRef.current?.click();
    };

    const getVideoDuration = (file: File): Promise<number> => {
        return new Promise((resolve, reject) => {
            const videoEle = document.createElement("video");
            const fileURL = URL.createObjectURL(file);

            videoEle.src = fileURL;

            videoEle.onloadedmetadata = () => {
                const duration = videoEle.duration;
                videoEle.remove();
                URL.revokeObjectURL(fileURL);
                resolve(duration);
            };

            videoEle.onerror = (ev) => {
                videoEle.remove();
                URL.revokeObjectURL(fileURL);
                reject("Error loading video metadata.");
            };
        });
    };

    const handleChangeFileInput = useCallback(
        async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
            const target = event.target;
            const files = target.files;
            
            if (!files) return;
            
            // if (!ffmpeg.loaded) {
            //     await ffmpeg.load();
            // }

            // ffmpeg.on("log", ({type, message}) => {
            //     console.log(" ::: TYPE ::: ", type, " ::: MESSAGE ::: ", message);
            // })

            const fileCount = files.length;
            const totalFileCount = imageCount + fileCount;
            
            if (totalFileCount > MAX_FILE_COUNT) {
                dispatch(setOpenSnackBar({
                    message: `Please choose up to ${MAX_FILE_COUNT} photos or videos.`,
                    type: ActionSnackbarStateType.WARNING
                }));
                return;
            }
            
            const validFiles: any = [];
            const allowedTypes = [
                "image/jpg",
                "image/jpeg",
                "image/png",
                "image/bmp",
                "video/mp4",
                "video/mov",
                "video/quicktime",
                // "video/mkv",
                // "video/x-matroska", // MKV extension
                // "video/webm",
                // "video/avi",
                // "video/x-ms-wmv",
                // "video/x-msvideo" // AVI extension
            ];
            
            for (let i = 0; i < fileCount; i++) {
                const file = files[i];
                const isVideo = file.type.includes("video");
                if (!allowedTypes.includes(file.type)) {
                    dispatch(setOpenSnackBar({
                        message: `${file.name || "Unknown"} has an unsupported file type.`,
                        type: ActionSnackbarStateType.ERROR
                    }));
                    continue;
                }
            
                if (file.size > 10 * 1024 * 1024) {
                    dispatch(setOpenSnackBar({
                        message: `${file.name || "Unknown"} exceeds 10 MB.`,
                        type: ActionSnackbarStateType.ERROR
                    }));
                    continue;
                }
            
                if (isVideo) {

                    // Comment out convert code
                    // ffmpeg.writeFile(file.name, await fetchFile(file));

                    // const outputFileName = `${file.name.split(".")?.[0]}.mp4`;
                    // await ffmpeg.exec([
                    //     "-i",
                    //     file.name,
                    //     "-c:v",
                    //     "libx264",
                    //     "-preset",
                    //     "ultrafast",
                    //     "-crf",
                    //     "28",
                    //     "-c:a",
                    //     "aac",
                    //     "-b:a",
                    //     "128k",
                    //     outputFileName
                    // ]);
            
                    // const videoData = await ffmpeg.readFile(outputFileName);
                    // const videoBlob = new Blob([videoData], {type: "video/mp4"});
                    // const fileVideo: File = new File([videoBlob], outputFileName, {
                    //     type: "video/mp4"
                    // });

                    // Check video duration
                    const duration = await getVideoDuration(file);
                    if (duration > 30) {
                        // setVideoFiles((prevState) => [...prevState, file]); // this used to show popup that trim video
                        dispatch(setOpenSnackBar({
                            message: `${file.name || "Unknown"} exceeds 30 seconds.`,
                            type: ActionSnackbarStateType.ERROR
                        }));
                        continue;
                    }

                    validFiles.push({ src: URL.createObjectURL(file), file: file });
                } else {
                    validFiles.push({ src: URL.createObjectURL(file), file });
                }
            }
            
            if (validFiles.length > 0) {
                console.log("tweetImages 3");
                handleSetImage(validFiles);
                dispatch(setImages(validFiles));
            }
            
            // Clear the input to allow re-selection of the same file
            target.value = "";
        },
        [dispatch, imageCount, handleSetImage]
    );

    // Reset state of video triming
    const handleCLoseVideoTriming = useCallback(() => {
        setIsVideoTrimingVisible(false);
        setVideoFiles([]);
    }, []);

    useEffect(() => {
        if (videoFiles.length && !isVideoTrimingVisible) {
            setIsVideoTrimingVisible(true);
        }
    }, [videoFiles.length, isVideoTrimingVisible]);

    return (
        <>
            <ActionIconButton
                actionText={"Media"}
                icon={MediaIcon}
                onClick={handleClickImage}
                size={"medium"}
                disabled={imageCount >= MAX_FILE_COUNT}
            />
            <input
                ref={inputRef}
                type="file"
                multiple
                id="upload-input"
                hidden
                accept="image/jpg, image/jpeg, image/png, image/bmp, video/mp4, video/avi, video/quicktime, video/mov, video/mkv"
                onChange={handleChangeFileInput}
            />

            <VideoTrimingModal
                videoFiles={videoFiles}
                visible={isVideoTrimingVisible}
                onClose={handleCLoseVideoTriming}
            />
        </>
    );
};

export default memo(UploadImages);
