import { makeStyles } from "@material-ui/core";

export const useExploreStyles = makeStyles((theme) => ({    
    container: {
        // position: 'relative',
        "& .MuiPaper-root": {
            width: '100%',
            display: 'block',
            position: 'static',
            // top: 0
        },
        "& .MuiInputBase-root": {
            width: 'unset',
        },
        "& .MuiFormControl-root": {
            flexGrow: 1,
        },
    },
    headerWrapper: {
        display: 'block',
        position: 'sticky',
        top: 0,
        width: 'unset',
        zIndex: 12,
        // '& > div': {
        // },
    },
    backButtonWrapper: {
        display: "inline-block",
        paddingTop: 5
    },
    editButton: {
        marginLeft: 'auto',
    },
    contentWrapper: {
        // paddingTop: 97
    },
    tabs: {
        overflow: 'auto',
        maxWidth: '100%',
        "& .MuiTabs-indicator": {
            marginLeft: 35,
            maxWidth: 50,
            height: 4,
            backgroundColor: theme.palette.primary.main
        },
        "& .MuiTab-root": {
            fontSize: 15,
            textTransform: "none !important",
            minWidth: 120,
            fontWeight: 700
        },
        "& .MuiTabs-scrollButtons": {
            transition: "opacity .5s ease-out",
        }
    }
}));
