import React, { FC, memo, ReactElement } from "react";
import { Link } from "react-router-dom";
import { Typography } from "@material-ui/core";

import { HOME_TWEET } from "../../../constants/path-constants";
import { textFormatter } from "../../../util/text-formatter";
import { useTweetTextStyles } from "./TweetTextStyles";
import LinkWrapper from "../../LinkWrapper/LinkWrapper";

interface TweetTextProps {
    text?: string;
    tweetId?: string;
    isDeclined?: boolean;
    isContentBlurred?: boolean;
}

const TweetText: FC<TweetTextProps> = memo(({ text, tweetId, isDeclined, isContentBlurred }): ReactElement => {
    const classes = useTweetTextStyles();

    const preventActions = (e: any) => {
        if (isContentBlurred) {
            e.preventDefault();
            return false;
        }
    }

    return (
        <Typography variant={"body1"} className={classes.text}
            onCopy={preventActions} // Prevent copying
            onContextMenu={preventActions} // Prevent right-click
            style={{ filter: isContentBlurred ? "blur(10px)" : "none", userSelect: isContentBlurred ? "none" : "auto" }}
        >
            <LinkWrapper id={"handleClickTweet"} path={`${HOME_TWEET}/${tweetId}`} disableClick={isDeclined}>
                {textFormatter(text!)}
            </LinkWrapper>
        </Typography>
    );
});

export default TweetText;
