import { FC, memo, ReactElement, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { MODAL } from "../../../constants/path-constants";
import { CloseIcon } from "../../../icons";
import { removeImages } from "../../../store/ducks/addTweetForm/actionCreators";
import { selectImages } from "../../../store/ducks/addTweetForm/selector";
import ActionIconButton from "../../ActionIconButton/ActionIconButton";
import AddDescription from "./AddDescription/AddDescription";
import { useAddTweetImageStyles } from "./AddTweetImageStyles";
import TagPeople from "./TagPeople/TagPeople";
import WestIcon from "@mui/icons-material/West";
import EastIcon from "@mui/icons-material/East";
import { DEFAULT_VIDEO_IMG } from '../../../constants/url-constants';
//import { useTweetForm } from "../../../context/TweetFormContext";
import { ImageObj } from "../AddTweetForm";

interface IProps {
    images: ImageObj[],
    handleImages: (index: number) => void;
}

const AddTweetImage: FC<IProps> = memo(({ images, handleImages }): ReactElement | null => {
    const classes = useAddTweetImageStyles();
    const dispatch = useDispatch();
    const location = useLocation();
    // const { tweetFormState: { images } } = useTweetForm();
    // const images = useSelector(selectImages);
    const [currentIndex, setCurrentIndex] = useState<number>(0);
    const [hideRightArrow, setHideRightArrow] = useState<boolean>(false);
    const [hideLeftArrow, setHideLeftArrow] = useState<boolean>(false);

    useEffect(() => {
        if (currentIndex >= 0 && images?.length && images?.length > 1) {
            setHideLeftArrow(false);
            setHideRightArrow(false);
            if (images?.length === 1) {
                setHideLeftArrow(true);
                setHideRightArrow(true);
            } else if (currentIndex === 0) {
                setHideLeftArrow(true);
            } else if (currentIndex + 1 === images?.length) {
                setHideRightArrow(true);
            }
        } else {
            setHideLeftArrow(true);
            setHideRightArrow(true)
        }
    }, [currentIndex, images?.length])


    useEffect(() => {
        if (images.length) {
            if (currentIndex == images.length) {
                handlePrevious();
                handleNext();
            } else {
                handleNext();
                handlePrevious();
            }
        } else {
            setCurrentIndex(0);
        }
    }, [images.length]);

    const onClickRemoveImage = (index: number): void => {
        dispatch(removeImages(index));
        handleImages(index)
    };

   
    const handleNext = () => {
        setCurrentIndex((prevIndex) => {
            return (prevIndex + 1) % images.length
        });
    };

    const handlePrevious = () => {
        setCurrentIndex((prevIndex) => {
            return (prevIndex - 1 + images.length) % images.length
        });
    };

    if (images.length === 0) {
        return null;
    }

    return (
        <div className={location.pathname.includes(MODAL) ? classes.imageSmall : classes.image}>
            {!hideLeftArrow && (
                <button
                    className={classes.arrowPrev}
                    onClick={handlePrevious}
                    style={{
                        display: images.length === 1 ? "none" : "block"
                    }}
                >
                    <WestIcon />
                </button>
            )}
            <div>
                <div className={`${classes.imageWrapper} currentIndex-${currentIndex}`} key={currentIndex}>
                    <ActionIconButton
                        actionText={"Remove"}
                        icon={CloseIcon}
                        onClick={() => onClickRemoveImage(currentIndex)}
                        size={"medium"}
                    />
                    {images[currentIndex]?.file?.type?.includes('video') ? (
                        <video controls className={classes.image} key={images[currentIndex]?.src}>
                            <source src={images[currentIndex]?.src} type={images[currentIndex]?.file?.type == "video/quicktime" ? "video/mp4" : images[currentIndex]?.file?.type} />
                        </video>
                    ) : (
                        <img
                            src={images[currentIndex]?.file && images[currentIndex]?.file?.type?.includes('video') ? DEFAULT_VIDEO_IMG : images[currentIndex]?.src}
                            alt={images[currentIndex]?.src}
                            className={classes.image}
                        />
                    )}
                </div>
            </div>
            {!hideRightArrow && (
                <button
                    className={classes.arrowNext}
                    onClick={handleNext}
                    style={{
                        display: images.length === 1 ? "none" : "block"
                    }}
                >
                    <EastIcon />
                </button>
            )}
            <div>
                <TagPeople />
                <AddDescription />
            </div>
        </div>
    );
});

export default AddTweetImage;
