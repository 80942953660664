import Paper from "@material-ui/core/Paper";
import { CompatClient, Stomp } from "@stomp/stompjs";
import classnames from "classnames";
import { ReactElement, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import SockJS from "sockjs-client";

import Spinner from "../../components/Spinner/Spinner";
import { APP_NAME } from "../../constants/common-constants";
import { WS_URL } from "../../constants/endpoint-constants";
import { TOPIC_USER_ADD_TWEET, TOPIC_USER_VOTE_TWEET } from "../../constants/ws-constants";
import { selectUserDataId, selectUserIsLoaded } from "../../store/ducks/user/selectors";
import {
    fetchImages,
    fetchUserProfile,
    resetImagesState,
    resetUserProfileState
} from "../../store/ducks/userProfile/actionCreators";
import {
    selectUserProfileFullName,
    selectUserProfileId,
    selectUserProfileIsFollower,
    selectUserProfileIsFollowing,
    selectUserProfileIsMutedDirectMessages,
    selectUserProfileIsMyProfileBlocked,
    selectUserProfileIsPrivateProfile,
    selectUserProfileIsUserBlocked,
    selectUserProfileIsWaitingForApprove,
    selectUserProfileScore,
    selectUserProfileUsername,
    selectUsersIsErrorLoaded,
    selectUsersIsLoading,
    selectUsersIsSuccessLoaded
} from "../../store/ducks/userProfile/selectors";
import {
    fetchUserTweets,
    resetUserTweets,
    setAddedUserTweet,
    setUserVote
} from "../../store/ducks/userTweets/actionCreators";
import { useGlobalStyles } from "../../util/globalClasses";
import AddUserToChatButton from "./AddUserToChatButton/AddUserToChatButton";
import BlockUserButton from "./BlockUserButton/BlockUserButton";
import CancelUserButton from "./CancelUserButton/CancelUserButton";
import EditProfileButton from "./EditProfileButton/EditProfileButton";
import FollowUserButton from "./FollowUserButton/FollowUserButton";
import NotificationButton from "./NotificationButton/NotificationButton";
import UnfollowUserButton from "./UnfollowUserButton/UnfollowUserButton";
import UserAvatar from "./UserAvatar/UserAvatar";
import UserBlockedMessage from "./UserBlockedMessage/UserBlockedMessage";
import UserDetails from "./UserDetails/UserDetails";
import UserFollowerGroup from "./UserFollowerGroup/UserFollowerGroup";
import UserInfo from "./UserInfo/UserInfo";
import UserInteractionCount from "./UserInteractionCount/UserInteractionCount";
import UserNotFound from "./UserNotFound/UserNotFound";
import UserPageActions from "./UserPageActions/UserPageActions";
import UserPageHeader from "./UserPageHeader/UserPageHeader";
import { useUserPageStyles } from "./UserPageStyles";
import UserPrivateProfileMessage from "./UserPrivateProfileMessage/UserPrivateProfileMessage";
import UserTweets from "./UserTweets/UserTweets";
import UserUnmuteMessage from "./UserUnmuteMessage/UserUnmuteMessage";
import UserWallpaper from "./UserWallpaper/UserWallpaper";
import { useUserFollow } from "../../context/UserFollowContext";
import { Typography } from "@material-ui/core";

let stompClient: CompatClient | null = null;

const UserPage = (): ReactElement => {
    const globalClasses = useGlobalStyles({});
    const classes = useUserPageStyles();
    const dispatch = useDispatch();
    const { followRecord } = useUserFollow();
    const myProfileId = useSelector(selectUserDataId);
    const userProfileId = useSelector(selectUserProfileId);
    const fullName = useSelector(selectUserProfileFullName);
    const username = useSelector(selectUserProfileUsername);
    const isPrivateProfile = useSelector(selectUserProfileIsPrivateProfile);
    const isFollower = useSelector(selectUserProfileIsFollower);
    const isFollowing = useSelector(selectUserProfileIsFollowing);
    const isMutedDirectMessages = useSelector(selectUserProfileIsMutedDirectMessages);
    const isUserBlocked = useSelector(selectUserProfileIsUserBlocked);
    const isMyProfileBlocked = useSelector(selectUserProfileIsMyProfileBlocked);
    const isWaitingForApprove = useSelector(selectUserProfileIsWaitingForApprove);
    const isMyProfileLoaded = useSelector(selectUserIsLoaded);
    const isUserProfileLoading = useSelector(selectUsersIsLoading);
    const isUserProfileSuccessLoaded = useSelector(selectUsersIsSuccessLoaded);
    const isUserProfileNotLoaded = useSelector(selectUsersIsErrorLoaded);
    const userProfileScore = useSelector(selectUserProfileScore);
    const params = useParams<{ userId: string }>();
    const [userTweetsActiveTab, setUserTweetsActiveTab] = useState<number>(0);
    const isFollow = followRecord[params.userId]?.isFollow == undefined ? false : followRecord[params.userId]?.isFollow;

    useEffect(() => {
        window.scrollTo(0, 0);

        if (params.userId) {
            dispatch(fetchUserProfile(params.userId));
            dispatch(fetchImages(params.userId));
        }
        document.body.style.overflow = "unset";

        stompClient = Stomp.over(() => new SockJS(WS_URL));
        stompClient.connect({}, () => {
            stompClient?.subscribe(TOPIC_USER_ADD_TWEET(params.userId), (response) => {
                dispatch(setAddedUserTweet(JSON.parse(response.body)));
            });
            // stompClient?.subscribe(TOPIC_USER_UPDATE_TWEET, (response) => {
            //     dispatch(setUpdatedUserTweet(JSON.parse(response.body)));
            // });
            stompClient?.subscribe(TOPIC_USER_VOTE_TWEET(params.userId), (response) => {
                dispatch(setUserVote(JSON.parse(response.body)));
            });
        });
        setUserTweetsActiveTab(0);

        return () => {
            dispatch(resetUserProfileState());
            dispatch(resetUserTweets());
            dispatch(resetImagesState());
            stompClient?.disconnect();
        };
    }, [params.userId]);

    useEffect(() => {
        if (isUserProfileSuccessLoaded) {
            document.title = `${fullName} (@${username}) | ${APP_NAME}`;
            dispatch(fetchUserTweets({ userId: params.userId, page: 0, activeTab: userTweetsActiveTab }));
        }

        return () => {
            document.title = APP_NAME;
            dispatch(resetUserTweets());
        };
    }, [isUserProfileSuccessLoaded]);

    const handleChangeUserTweetsTab = useCallback((newValue: number): void => {
        setUserTweetsActiveTab(newValue);
    }, []);

    const calculationUserScore = () => {
        if (userProfileScore) {
            return Math.floor(userProfileScore);
        }
        return 0;
    };

    const getColor = () => {
        const score = calculationUserScore();
        if (score >= 80) {
            return "#24BB10";
        } else if (score >= 50) {
            return "#EDA31A";
        } else {
            return "#EC1111";
        }
    };

    const getUserScoreLabel = () => {
        const score = calculationUserScore();
        if (score >= 80) {
            return {
                text: "This user can typically be trusted",
                label: "Trusted"
            };
        } else if (score >= 50) {
            return {
                text: "This user can typically be somewhat trusted",
                label: "Somewhat Trusted"
            };
        } else {
            return {
                text: "This user cannot typically be trusted",
                label: "Not Trusted"
            };
        }
    };

    return (
        <>
            {isUserProfileNotLoaded ? (
                <UserNotFound />
            ) : (
                <Paper className={classnames(globalClasses.pageContainer, classes.container)} variant="outlined">
                    <UserPageHeader userTweetsActiveTab={userTweetsActiveTab} />
                    <div className={globalClasses.contentWrapper}>
                        <UserWallpaper />
                        <div className={classes.info}>
                            <UserAvatar />
                            {isMyProfileLoaded &&
                                isUserProfileSuccessLoaded &&
                                (isMyProfileBlocked ? null : userProfileId === myProfileId ? (
                                    <EditProfileButton />
                                ) : (
                                    <div className={classes.buttonWrapper}>
                                        <UserPageActions />
                                        {(!isPrivateProfile || isFollower) &&
                                            !isMutedDirectMessages &&
                                            !isUserBlocked && <AddUserToChatButton />}
                                        {isUserBlocked ? (
                                            <BlockUserButton />
                                        ) : isFollowing || isFollow ? (
                                            <>
                                                {/* <NotificationButton /> */}
                                                <UnfollowUserButton />
                                            </>
                                        ) : (
                                            userProfileId &&
                                            (isWaitingForApprove ? <CancelUserButton /> : <FollowUserButton />)
                                        )}
                                    </div>
                                ))}
                            <UserInfo />
                            <div className={classes.infoList}>
                                <UserDetails />
                                <UserInteractionCount />
                            </div>
                            <UserUnmuteMessage />
                            <UserFollowerGroup />
                            {userProfileScore !== undefined && (
                                <div className={classes.postReportWrapper}>
                                    <div className={classes.postCounter} style={{ backgroundColor: getColor() }}>
                                        {calculationUserScore()}
                                    </div>
                                    <div>
                                        {/* <p className={classes.heading}>{getUserScoreLabel().text}</p> */}
                                            <Typography
                                                variant={"body1"}
                                                className={classes.heading}
                                            >
                                                {getUserScoreLabel().text}
                                                {/* <ThumbUpOffAltIcon /> */}
                                            </Typography>
                                        {/* <p className={classes.content}>
                                            {getUserScoreLabel().label}: This tweet appears unreliable and may contain
                                            false or misleading information. It does not match trusted sources and
                                            should be approached with caution.
                                        </p> */}
                                            <Typography
                                                variant={"body1"}
                                                className={classes.content}
                                            >
                                                {getUserScoreLabel().label}: This tweet appears unreliable and may contain
                                            false or misleading information. It does not match trusted sources and
                                            should be approached with caution.
                                            </Typography>
                                    </div>
                                </div>
                            )}
                        </div>
                        {isUserProfileLoading ? (
                            <Spinner />
                        ) : (
                            isUserProfileSuccessLoaded &&
                            (isMyProfileBlocked || isUserBlocked ? (
                                <UserBlockedMessage
                                    isMyProfileBlocked={isMyProfileBlocked ?? false}
                                    isUserBlocked={isUserBlocked ?? false}
                                />
                            ) : isPrivateProfile && !isFollower && userProfileId !== myProfileId ? (
                                <UserPrivateProfileMessage />
                            ) : (
                                <UserTweets
                                    userTweetsActiveTab={userTweetsActiveTab}
                                    handleChangeUserTweetsTab={handleChangeUserTweetsTab}
                                />
                            ))
                        )}
                    </div>
                </Paper>
            )}
        </>
    );
};

export default UserPage;
