import React, { useState, useRef, useEffect, useCallback, FC } from "react";
import TweetComponent from "../../../components/TweetComponent/TweetComponent";
import { TweetResponse } from "../../../types/tweet";

interface VirtualListProps {
    listItems: TweetResponse[];
}

const VirtualList: FC<VirtualListProps> = ({ listItems }): React.ReactElement => {
    const [visibleRange, setVisibleRange] = useState({ start: 0, end: 0 });
    const itemPositions = useRef<number[]>([]);
    const itemHeights = useRef<number[]>([]);
    const scrollPosition = useRef<number>(0);
    const buffer = 8;

    const calculateItemPositions = useCallback(() => {
        const positions = [0];
        let totalHeight = 0;

        for (let i = 0; i < listItems.length; i++) {
            totalHeight += itemHeights.current[i] + 25 || 0; // +25 for padding
            positions.push(totalHeight);
        }

        itemPositions.current = positions;
    }, [listItems.length]);

    const findStartIndex = (scrollTop: number) => {
        let start = 0;
        let end = listItems.length;

        while (start <= end) {
            const mid = Math.floor((start + end) / 2);
            if (itemPositions.current[mid] <= scrollTop) {
                start = mid + 1;
            } else {
                end = mid - 1;
            }
        }
        return Math.max(0, start - 1);
    };

    const updateVisibleItems = useCallback(() => {
        const scrollTop = window.scrollY || document.documentElement.scrollTop;
        const containerHeight = window.innerHeight;

        // Save current scroll position before updates
        scrollPosition.current = scrollTop;

        const startIndex = findStartIndex(scrollTop);
        const endIndex = findStartIndex(scrollTop + containerHeight);

        setVisibleRange({
            start: Math.max(0, startIndex - buffer),
            end: Math.min(listItems.length, endIndex + buffer)
        });
    }, [listItems.length, buffer]);

    // Add debounce to handleResize
    const handleResize = useCallback(
        (index: number, height: number) => {
            if (itemHeights.current[index] !== height) {
                itemHeights.current[index] = height;

                // Batch updates using requestAnimationFrame
                requestAnimationFrame(() => {
                    calculateItemPositions();
                    updateVisibleItems();

                    // Maintain scroll position after resize
                    window.scrollTo({
                        top: window.scrollY,
                        behavior: "auto"
                    });
                });
            }
        },
        [calculateItemPositions, updateVisibleItems]
    );

    useEffect(() => {
        calculateItemPositions();
        updateVisibleItems();
        const handleScroll = () => requestAnimationFrame(updateVisibleItems);
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [calculateItemPositions, updateVisibleItems]);

    return (
        <div style={{ position: "relative" }}>
            <div style={{ height: itemPositions.current[listItems.length] || 0 }}>
                {listItems.slice(visibleRange.start, visibleRange.end).map((item, index) => {
                    const actualIndex = visibleRange.start + index;
                    return (
                        <div
                            key={`${actualIndex}-${item.id}`} // Use unique item ID
                            style={{
                                position: "absolute",
                                top: `${itemPositions.current[actualIndex]}px`,
                                width: "100%",
                                transition: "top 0.2s linear"
                            }}
                        >
                            <TweetComponent
                                tweet={item}
                                onHeightChange={(height) => handleResize(actualIndex, height)}
                            />
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default VirtualList;
