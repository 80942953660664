import React, { createContext, useContext, useState, ReactNode, useMemo } from "react";

interface FollowRecord {
    [userId: string]: {
        isFollow: boolean;
        path: string;
    } | undefined
}

interface FollowRecordPayload {
    userId: string;
    isFollow: boolean;
    path: string;
}

// Create a Context for the popover state
interface UserFollowContextProps {
    followRecord: FollowRecord;
    handleFollowRecord: (payload: FollowRecordPayload) => void;
}

const UserFolllowContext = createContext<UserFollowContextProps | undefined>(undefined);

// Define the type for the provider's props (which includes children)
interface UserFollowProviderProps {
    children: ReactNode; // ReactNode type is used to allow anything React can render (JSX, strings, etc.)
}

// Create a provider component with path to track down last action from
export const UserFollowProvider: React.FC<UserFollowProviderProps> = ({ children }) => {
    const [followRecord, setFollowRecord] = useState<FollowRecord>({});

    const handleFollowRecord = (payload: FollowRecordPayload) => {
        setFollowRecord((prev) => {
            return {
                ...prev,
                [payload.userId]: {
                    isFollow: payload.isFollow,
                    path: payload.path
                }
            }
        })
    }

    // Memoize the context value to avoid unnecessary re-renders
    const value = useMemo(
        () => ({
            followRecord,
            handleFollowRecord
        }),
        [followRecord]
    );

    return <UserFolllowContext.Provider value={value}>{children}</UserFolllowContext.Provider>;
};

// Custom hook to use the UserFolllowContext
export const useUserFollow = () => {
    const context = useContext(UserFolllowContext);
    if (!context) {
        throw new Error("useUserFollow must be used within a UserFollowProvider");
    }
    return context;
};
